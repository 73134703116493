import presetService from "../services/preset";

export default {
  namespaced: true,
  state: {
    presets: {},
    loading: false,
    selected: ""
  },
  getters: {
    hasNext(state) {
      return state.presets && state.presets.next !== null;
    },
    hasPrevious(state) {
      return state.presets && state.presets.previous !== null;
    },
    currentPreset(state) {
      return (
        state.selected &&
        state.selected.length > 0 &&
        state.presets &&
        state.presets.results &&
        state.presets.results.filter(item => item.id === state.selected.id)
      );
    }
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      if (process.env.NODE_ENV === "development") {
        setTimeout(() => {
          state.loading = false;
        }, 500);
      } else {
        state.loading = false;
      }
    },
    removePreset(state, id) {
      const index = state.presets.findIndex(item => item.id === id);
      state.presets.splice(index, 1);
      if (state.selected === id) {
        state.selected = "";
      }
    },
    saveListPreset(state, presets) {
      state.presets = { ...presets };
    },
    saveCurrentPreset(state, id) {
      state.selected = id;
    }
  },
  actions: {
    async load({ state, commit }, { direction, session_type }) {
      commit("startLoading");

      let url = state.presets[direction];
      const response = await presetService.list(url, session_type);
      commit("saveListPreset", response.data);
      commit("finishLoading");
    },
    remove({ commit }, payload) {
      commit("removePreset", payload);
    },
    updateCurrentPreset({ commit }, payload) {
      commit("saveCurrentPreset", payload);
    }
  }
};
