import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { VueCookieNext } from 'vue-cookie-next'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    components: { default: () => import("@/views/Discovery.vue") },
    children: [
      {
        path: "",
        name: "select-app-default",
        components: {
          "center-content": () => import("@/components/session/SelectType.vue")
        }
      }
    ]
  },
  {
    path: "/detect-monitor",
    name: "detect-monitor",
    component: () => import("@/views/DetectMonitor.vue"),
    props: {
      mode: "map"
    }
  },
  {
    path: "/instant-search",
    name: "instant-search",
    component: () => import("@/views/InstantSearch.vue"),
    props: {
      mode: "map"
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: () => import("@/views/PrivacyPage.vue")
  },
  {
    path: "/terms-of-service",
    name: "tos",
    component: () => import("@/views/TermOfServicePage.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes
});

router.beforeEach((to, from, next) => {
  // const isLoggedIn = localStorage.getItem("profile");
  const isLoggedIn = true; //trang.tn edit to remove disc login

  // if (to.path !== "/") {
  //   return next();
  // }

  // if (isLoggedIn || from.name !== null) {
  //   return next();
  // } else {
  //   return next({ name: "instant-search" });
  // }

  if ((!VueCookieNext.getCookie('auth_token')) && (!to.query.token)) {
    window.location.href = process.env.VUE_APP_CLAREA_URL + "?redirect=" + process.env.VUE_APP_DISCOVERY_URL;
    return;
  }

  if (to.query.token) {
    VueCookieNext.setCookie('auth_token', to.query.token);
    signInWithCustomToken(getAuth(), VueCookieNext.getCookie('auth_token'))
      .then(data => {// loged in
        VueCookieNext.setCookie('auth_uid', data.user.uid);
        VueCookieNext.setCookie('auth_displayname', data.user.displayName);
        // next({ name: "instant-search" });
        if (to.path !== "/") {
          return next();
        }
        if (isLoggedIn || from.name !== null) {
          // return next();
          return next({ name: "select-app-default" });
        } else {
          return next({ name: "instant-search" });
        }
      })
      .catch(function (error) {
        if (error.code === 'auth/invalid-custom-token') {
          VueCookieNext.removeCookie('auth_token');
          VueCookieNext.removeCookie("auth_uid");
          window.location.href = process.env.VUE_APP_CLAREA_URL + "?redirect=" + process.env.VUE_APP_DISCOVERY_URL;
          return
        } else {
          VueCookieNext.removeCookie('auth_token');
          VueCookieNext.removeCookie("auth_uid");
          window.location.href = process.env.VUE_APP_CLAREA_URL + "?redirect=" + process.env.VUE_APP_DISCOVERY_URL;
          return;
        }
      });
  } else {
    getAuth().onAuthStateChanged(user => {
      if (user) {//da login
        // loged in
        VueCookieNext.setCookie('auth_uid', user.uid);
        VueCookieNext.setCookie('auth_displayname', user.displayName);
        // if (!to.name) {
        //   next({ name: "instant-search" });
        // }
        // next();

        if (to.path !== "/") {
          return next();
        }
        if (isLoggedIn || from.name !== null) {
          return next();
        } else {
          return next({ name: "instant-search" });
        }
      } else {
        // not yet login
        VueCookieNext.removeCookie('auth_token');
        VueCookieNext.removeCookie("auth_uid");
        window.location.href = process.env.VUE_APP_CLAREA_URL + "?redirect=" + process.env.VUE_APP_DISCOVERY_URL;
        return;
      }
    });
  }
});

export default router;
