import "url-search-params-polyfill";
import "./config/axios";
import "vuetify-dialog/dist/vuetify-dialog.css";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import App from "./App.vue";
import MapboxPlugin from "./plugins/mapbox";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import VuetifyDialog from "vuetify-dialog";
import axios from "./config/axios";
import i18n from "./config/i18n";
import router from "./router";
import store from "./store";
import vuetify from "./config/vuetify";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

Vue.config.productionTip = false;

Vue.use(PerfectScrollbar);
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ID,
  router
});

Vue.use(MapboxPlugin, {
  MAPBOX_TOKEN: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN
});

Vue.prototype.$http = axios;
Vue.prototype.$to = function to(promise) {
  return promise
    .then(response => [response, null])
    .catch(error => [null, error]);
};

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJ_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MESUREMENT_ID
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();

const vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  i18n
});

vm.$mount("#app");
axios.$v = vm;

export default vm;
