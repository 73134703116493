<template>
  <df-drawer :active="active" @changed="active = $event"></df-drawer>
</template>

<script>
import DfDrawer from "./general/DfDrawer";
import bus from "../event-bus";

export default {
  components: {
    DfDrawer
  },
  data() {
    return {
      active: false
    };
  },
  mounted() {
    bus.$off("show-sidebar");
    bus.$on("show-sidebar", () => {
      this.active = true;
    });
  }
};
</script>
