export default {
  DEFAULT_MAP_ID: "1995",
  DEFAULT_ANIMATE_TIME: 500, //ms
  FEATURE_LAYER_NAME: "feature-layer",
  CUSTOM_AREA_LAYER_NAME: "custom-area-layer",
  CENTER_FEATURE_NAME: "center-feature",
  DEFAULT_DATA_PROJECTION: "EPSG:4326",
  DEFAULT_FEATURE_PROJECTION: "EPSG:3857",
  MAP_CENTER_LAYER_NAME: "map-center-layer",
  MAP_CENTER_ROI_PROP: "center-roi",
  MAP_CENTER_ROI_CUSTOM_PROP: "center-roi-custom",
  DEFAULT_SEARCH_DELAY: 1200,
  DEFAULT_ROI_SIZE: "60vh",
  SEARCH_LAYER_NAMES: {
    //layer to draw mouse move ROI
    LAYER_MOUSE: "MouseLayer",
    //layer to draw last selected ROI
    LAYER_SELECTED: "SelectedLayer",
    //layer to display search result
    LAYER_RESULT: "ResultLayer"
  },
  GRID_LAYER_NAME: "grid",
  API: {
    INSTANT_SEARCH:
      process.env.VUE_APP_USE_CORS_PROXY === "yes"
        ? `https://cors-anywhere.herokuapp.com/${process.env.VUE_APP_INSTANT_SEARCH_API_URL}`
        : `${process.env.VUE_APP_INSTANT_SEARCH_API_URL}`
  },
  MAP: {
    INSTANT_SEARCH: {
      MAP_URL: process.env.VUE_APP_MAPBOX_INSTANT_SEARCH,
      MAP_EXTENT: [121.34, 30.981, 121.813, 32.212],
      MIN_ZOOM: 10,
      MAX_ZOOM: 18,
      DEFAULT_ZOOM: 17,
      DEFAULT_COORDINATES: [121.5612, 31.3752],
      CONFIG: {
        shanghai_19_01: {
          template: "shanghai_19_01_{row}_{col}",
          grid_image_url:
            process.env.VUE_APP_API_BASE_URL +
            "/images/instant-search/thumbnail/",
          dLong: 0.0005749796511622662,
          dLat: 0.0005758497095840198,
          //value add up to extent to fix image cropping drifts
          delta: {
            north: -0.0005758497095840198 * 0.5,
            west: 0.0005749796511622662 * 0.2
          },
          extent: {
            north: 32.174001000000025,
            south: 30.984295500000027,
            west: 121.41756449983514,
            east: 121.61535749983514
          }
        },
        shanghai_19_02: {
          template: "shanghai_19_02_{row}_{col}",
          grid_image_url:
            process.env.VUE_APP_API_BASE_URL +
            "/images/instant-search/thumbnail/",
          dLong: 0.0005757250000044678,
          dLat: 0.0005757572792361998,
          //value add up to extent to fix image cropping drifts
          delta: {
            north: -0.0005757572792361998 * 0.89,
            west: 0.0005757250000044678 * 0.05
          },
          extent: {
            north: 32.17987799999999,
            south: 30.97366649999999,
            west: 121.58856000000013,
            east: 121.79582100000013
          }
        }
      }
    }
  },
  INSTANT_SEARCH_SHOW_GRID: false
};
