<template>
  <v-app v-if="loggedin">
    <v-fade-transition v-if="$store.state.initializing">
      <splash-screen></splash-screen>
    </v-fade-transition>

    <template v-else>
      <app-header></app-header>
      <sidebar></sidebar>
      <v-content>
        <v-container fluid grid-list-xs pa-0>
          <router-view />
        </v-container>
      </v-content>
      <df-login-dialog
        v-if="!$store.state.user.refresh_token"
      ></df-login-dialog>
    </template>
  </v-app>
</template>

<script>
import AppHeader from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
import DfLoginDialog from "@/components/general/DfLoginDialog.vue";
import SplashScreen from "@/components/SplashScreen.vue";
import { VueCookieNext } from "vue-cookie-next";

export default {
  components: {
    AppHeader,
    Sidebar,
    SplashScreen,
    DfLoginDialog
  },

  data: () => ({
    initializing: true,
    loggedin:
      !VueCookieNext.getCookie("auth_token") &&
      !VueCookieNext.getCookie("auth_uid")
        ? false
        : true,
  }),

  mounted() {
    if (this.$store.state.initializing) {
      this.$store.dispatch("initAsync");
    }
  }
};
</script>

<style lang="scss">
//styles are loaded using style-resources-loader
</style>
