<template>
  <v-card
    class="type-card fill-height"
    :class="{
      active: $route.name === 'instant-search',
      'item-mode': itemMode
    }"
    :to="(itemMode && '/instant-search') || ''"
  >
    <v-img
      class="white--text align-end"
      height="200px"
      :src="require('@/assets/instant-search.png')"
    ></v-img>

    <v-card-title class="font-weight-bold">{{
      $t("discovery.instant_search")
    }}</v-card-title>

    <v-card-text class="text--primary">
      <div v-html="$t('discovery.instant_search_description')"></div>
    </v-card-text>

    <v-card-actions class="pl-0 mt-6" v-if="!itemMode">
      <v-btn class="pr-4 ml-2" color="teal" text exact to="/instant-search">
        <v-icon left>mdi-chevron-right</v-icon>
        {{ buttonText || $t("buttons.next") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    buttonText: { type: String },
    itemMode: { type: Boolean, default: false }
  }
};
</script>

<style scoped lang="scss">
@mixin active-border {
  border: 4px solid $secondary-color;
  border-radius: 4px !important;
}

.item-mode {
  border: 4px solid #fff;
  border-radius: 4px !important;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    @include active-border;
  }
}

.active {
  @include active-border;
}
</style>
