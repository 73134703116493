import Vue from "vue";
import Vuex from "vuex";
import preset from "./preset";
import session from "./session";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    initializing: true,
    isLoginDialogVisible: false,
    form: "", //or "register"
    loginDialogPersistent: false
  },

  mutations: {
    finishInit(state) {
      state.initializing = false;
    },
    updateDialogVisibility(state, payload) {
      //reset to default
      state.form = payload.form !== undefined ? payload.form : "login";
      state.isLoginDialogVisible =
        payload.visible !== undefined
          ? payload.visible
          : !state.isLoginDialogVisible;
    },
    updateCurrentDialogForm(state, payload) {
      state.form = payload;
    },
    toggleLoginDialogPersistent(state, payload) {
      if (payload !== undefined) {
        state.loginDialogPersistent = payload;
      } else {
        state.loginDialogPersistent = !state.loginDialogPersistent;
      }
    }
  },

  actions: {
    async initAsync({ commit, dispatch }) {
      const refresh = localStorage.getItem("refresh_token");
      const access = localStorage.getItem("access_token");
      if (!refresh || !access) {
        commit("finishInit");
        return dispatch("user/logout");
      }
      commit("user/saveTokens", { refresh, access });
      dispatch("user/getProfile");
      commit("finishInit");
    },
    toggleLoginDialog({ commit }, payload) {
      commit("updateDialogVisibility", payload);
    },
    toggleLoginFormDialog({ commit }, payload) {
      commit("updateCurrentDialogForm", payload);
    },
    toggleLoginDialogPersistent({ commit }, payload) {
      commit("toggleLoginDialogPersistent", payload);
    }
  },

  modules: {
    session,
    user,
    preset
  }
});
