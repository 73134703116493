<template>
  <div class="d-flex align-center mr-3">
    <!-- <v-toolbar-title class="mr-5">
      衛星データの解析ならDATAFLUCT
    </v-toolbar-title> -->
    <!-- <v-btn depressed rounded class="btn-about" :href="url" target="_blank">
      お問い合わせ
    </v-btn> -->
    <v-btn
      depressed
      rounded
      class="btn-about"
      v-on:click="menuclick"
      target="_blank"
    >
      メニューへ戻る
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
  },
  methods: {
    menuclick() {
      window.location.href = process.env.VUE_APP_CLAREA_URL;
    },
  },
};
</script>

<style scoped>
.btn-about {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 100%
  );
  color: #012a5d !important;
  font-weight: 500;
  cursor: pointer !important;
}
</style>
