<template>
  <v-navigation-drawer
    absolute
    temporary
    :value="active"
    right
    @input="$emit('changed', $event)"
    width="360"
  >
    <v-list nav dense>
      <div class="pa-2">
        <card-detect :itemMode="true" />
      </div>
      <div class="pa-2">
        <card-instant-search :itemMode="true" />
      </div>
    </v-list>
    <div @click="logout" class="logout-card">ログアウト</div>
  </v-navigation-drawer>
</template>

<script>
import CardDetect from "../session/CardDetect";
import CardInstantSearch from "../session/CardInstantSearch";
import { VueCookieNext } from "vue-cookie-next";
import { getAuth, signOut } from "firebase/auth";
import axios from "axios";

export default {
  components: {
    CardDetect,
    CardInstantSearch,
  },
  props: {
    active: { type: Boolean },
  },
  computed: {},
  methods: {
    logout() {
      axios
        .get(
          process.env.VUE_APP_SSO_SERVER +
            "user/revokeRefreshTokens?uid=" +
            VueCookieNext.getCookie("auth_uid")
        )
        .then((response) => {
          VueCookieNext.removeCookie("auth_token");
          VueCookieNext.removeCookie("auth_uid");
          window.location.href = process.env.VUE_APP_CLAREA_URL;
        });
      signOut(getAuth())
        .then((data) => {
          VueCookieNext.removeCookie("auth_token");
          VueCookieNext.removeCookie("auth_uid");
          // this.$router.replace({ name: "home" });
          window.location.href = process.env.VUE_APP_CLAREA_URL;
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
};
</script>

<style lang="scss">
.logout-card {
  padding-top: 6px;
  display: block;
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: -webkit-center;
  border: thin solid rgb(0 0 0 / 35%);
  left: 0px;
  height: 40px;
  color: #ff6a61;

  cursor: pointer;

  &:hover {
    color: #647fa0;
  }
}
.v-navigation-drawer--close {
  display: none !important;
}
</style>
