<template>
  <v-row justify="center">
    <v-dialog
      ref="dialog"
      width="500px"
      min-width="400px"
      max-width="500px"
      v-model="dialog"
      :persistent="$store.state.loginDialogPersistent"
    >
      <DfRegisterForm
        ref="formWrapper"
        v-show="$store.state.form === 'register'"
      />
      <DfLoginForm ref="formWrapper" v-show="$store.state.form === 'login'" />
    </v-dialog>
  </v-row>
</template>

<script>
import DfLoginForm from "../forms/DfLoginForm";
import DfRegisterForm from "../forms/DfRegisterForm";

export default {
  components: {
    DfLoginForm,
    DfRegisterForm
  },
  isActive(val) {
    if (!val) {
      this.$refs.formWrapper.reset();
    }
  },
  computed: {
    isActive() {
      return this.$refs.dialog.isActive;
    },

    dialog: {
      get() {
        return this.$store.state.isLoginDialogVisible;
      },
      set() {
        this.$store.dispatch(
          "toggleLoginDialog",
          { form: this.$store.state.form },
          { root: true }
        );
      }
    }
  }
};
</script>
