import userService from "../services/users";

export default {
  namespaced: true,
  state: {
    profile: {},
    type: "",
    access_token: null,
    refresh_token: null
  },
  getters: {
    isLoggedIn(state) {
      // return state.refresh_token && state.access_token;
      return true; //trang.tn edit to remove disc login
    },
    profileName(state) {
      const fullName =
        (state.profile.first_name &&
          state.profile.first_name.length > 0 &&
          state.profile.last_name &&
          state.profile.last_name.length > 0 &&
          `${state.profile.first_name} ${state.profile.last_name}`) ||
        null;

      const profileName =
        (state.profile &&
          (fullName || state.profile.email || state.profile.username)) ||
        "";
      let result = "";

      if (profileName.indexOf("@") >= 0) {
        result = profileName.substring(0, profileName.indexOf("@"));
      } else {
        result = profileName;
      }
      return result;
    }
  },
  mutations: {
    saveProfile(state, userInfo) {
      state.profile = {
        ...state.profile,
        ...userInfo
      };
    },
    saveTokens(state, tokens) {
      state.access_token = tokens.access;
      state.refresh_token = tokens.refresh;
    }
  },
  actions: {
    logout({ commit, dispatch }) {
      localStorage.clear();
      commit("saveTokens", {
        refresh_token: null,
        access_token: null
      });
      commit("saveProfile", {});

      dispatch("toggleLoginDialogPersistent", false, { root: true });
      dispatch(
        "toggleLoginDialog",
        { form: "login", visible: false },
        { root: true }
      );
    },
    async getProfile({ commit }) {
      const response = await userService.getProfile();
      localStorage.setItem("profile", JSON.stringify(response.data));
      commit("saveProfile", response.data);
    },
    async doLogin({ commit, dispatch }, credentials) {
      const tokens = await userService.login(credentials);

      if (tokens.data && tokens.data.access && tokens.data.refresh) {
        commit("saveTokens", tokens.data);
        await Promise.all([
          localStorage.setItem("access_token", tokens.data.access),
          localStorage.setItem("refresh_token", tokens.data.refresh)
        ]);
        dispatch("getProfile");
      }
    },
    async doRegister({ commit }, credentials) {
      return await userService.register(credentials);
    }
  }
};
