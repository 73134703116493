import mbxClient from "@mapbox/mapbox-sdk";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import mbxStatic from "@mapbox/mapbox-sdk/services/static";

const MapboxPlugin = {
  install(Vue, options) {
    const baseClient = mbxClient({ accessToken: options.MAPBOX_TOKEN });

    Vue.prototype.$mapbox = {
      client: baseClient,
      staticImage: mbxStatic(baseClient),
      geocoding: mbxGeocoding(baseClient)
    };
  }
};

export default MapboxPlugin;
