import i18n from "../../config/i18n";
import * as yup from "yup";

const registerSchema = yup.object().shape({
  email: yup
    .string()
    .label(i18n.t("login_form.email"))
    .email()
    .required(),
  password: yup
    .string()
    .label(i18n.t("login_form.password"))
    .min(8),
  re_password: yup
    .string()
    .label(i18n.t("login_form.password_confirmation"))
    .oneOf(
      [yup.ref("password"), null],
      i18n.t("login_form.errors.passwords_do_not_match")
    )
    .required(),
  acceptTermsOfService: yup
    .bool()
    .oneOf([true], i18n.t("login_form.errors.please_accept_terms_of_service"))
});

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .label(i18n.t("login_form.email"))
    .email()
    .required(),
  password: yup
    .string()
    .label(i18n.t("login_form.password"))
    .min(8)
});

export { registerSchema, loginSchema };
