import { isEqual, omit } from "lodash";

import Constants from "../config/constants";
import GeoJSON from "ol/format/GeoJSON";
import i18n from "../config/i18n";
import instantSearchService from "../services/instantSearch";
import objectTypesService from "../services/objectTypes";
import presetService from "../services/preset";
import router from "../router";
import { toLonLat } from "ol/proj";

export const SessionTypes = {
  detect: "detect",
  search: "search"
};

export const DefaultSession = {
  location_name: "",
  address: "",
  latitude: process.env.VUE_APP_LAT_CENTER,
  longitude: process.env.VUE_APP_LNG_CENTER,
  roi: {},
  session_type: SessionTypes.detect,
  detected: false
};

export default {
  namespaced: true,
  state: {
    selectedYear: "",
    session: DefaultSession,
    lastSavedSessionData: {},
    isDragCustomMode: false,
    isFinishDragCustom: false,
    savedAddresses: [],
    //detect and monitor
    objectTypes: [],
    availableObjectTypes: [],
    selectedObjectTypes: [],
    //instant search
    search: {
      selected_image_id: "",
      location: [],
      resultLimit: 100,
      results: {}
    },
    //support loading and saving state
    saving: false,
    loading: false
  },
  getters: {
    sessionType(state) {
      return (state.session && state.session.session_type) || "";
    },
    sessionAddress(state) {
      return (
        (state.session && {
          location_name: state.location_name,
          address: state.address,
          latitude: state.latitude,
          longitude: state.longitude
        }) ||
        DefaultSession
      );
    },
    sessionRoi(state) {
      return (state.session && state.session.roi) || {};
    },
    shouldEnableSavePreset(state) {
      return (
        state.session.address.length > 0 &&
        ((state.session.session_type === SessionTypes.detect &&
          state.isFinishDragCustom) ||
          !state.isDragCustomMode ||
          (state.session.session_type === SessionTypes.search &&
            Object.keys(state.search.results).length > 0))
      );
    }
  },

  mutations: {
    setDetected(state, detected) {
      state.session.detected = detected;
    },
    setSelectedYear(state, year) {
      state.selectedYear = year;
    },
    saveSession(state, session) {
      state.session = session;
    },
    saveSessionAddress(state, { location_name, address, latitude, longitude }) {
      state.session.location_name = location_name;
      state.session.address = address;
      state.session.latitude = latitude;
      state.session.longitude = longitude;
    },
    saveObjectTypes(state, objectTypes) {
      state.objectTypes = objectTypes;
    },
    saveAvailableObjectTypes(state, objectTypeIds) {
      state.availableObjectTypes = [...objectTypeIds];
    },
    saveSelectedObjectTypes(state, selectedIds) {
      state.selectedObjectTypes = [...selectedIds];
    },
    toggleSelectedObjectType(state, objectTypeId) {
      const index = state.selectedObjectTypes.findIndex(
        item => item === objectTypeId
      );
      if (index >= 0) {
        //remove
        state.selectedObjectTypes.splice(index, 1);
      } else {
        //push
        state.selectedObjectTypes.push(objectTypeId);
      }
    },
    saveSessionType(state, sessionType) {
      state.session.session_type = sessionType;
    },
    saveSearchResults(state, searchResults) {
      state.search.results = { ...searchResults };
    },
    saveSearchResultLimit(state, payload) {
      state.search.resultLimit = payload;
    },
    saveSearchLocation(state, payload) {
      state.search.location = [...payload.coordinates];
      state.search.selected_image_id = payload.id;
    },
    toggleIsDragCustomMode(state, payload) {
      state.isDragCustomMode = payload;
    },
    toggleFinishCustomMode(state, payload) {
      state.isFinishDragCustom = payload;
    },
    savingStatus(state, isSaving) {
      state.saving = isSaving;
    },
    loadingStatus(state, isLoading) {
      state.loading = isLoading;
    },
    saveLastSavedSessionData(state, payload) {
      state.lastSavedSessionData = payload;
    },
    resetSession(state) {
      state.selectedObjectTypes = [];
      state.availableObjectTypes = [];
      state.session.detected = false;
      state.isDragCustomMode = false;
      state.session = {
        ...DefaultSession
      };
    }
  },

  actions: {
    resetSession({ commit, dispatch }) {
      commit("resetSession");
      dispatch("preset/updateCurrentPreset", "", { root: true });
    },
    updateSelectedYear({ commit }, payload) {
      commit("setSelectedYear", payload);
    },
    updateDetected({ commit }, payload) {
      commit("setDetected", payload);
    },
    updateSingleSelectedObjectType({ commit }, payload) {
      commit("toggleSelectedObjectType", payload);
    },
    updateSelectedObjectTypes({ commit }, payload) {
      commit("saveSelectedObjectTypes", payload);
    },
    async loadObjectTypes({ commit, state }, payload) {
      const useCache = payload && payload.useCache;
      if (useCache && state.objectTypes.length > 0) {
        return Promise.resolve(true);
      }
      const response = await objectTypesService.getObjectTypes();
      commit("saveObjectTypes", response.data);
    },
    async newSession({ commit, state }) {
      const session = await objectTypesService.postSession({
        session_data: {
          ...state.session
        }
      });
      commit("saveSession", session.data);
      router.replace(`/session/${session.data.id}`);
    },
    updateAvailableObjectTypes({ commit }, payload) {
      commit("saveAvailableObjectTypes", payload);
    },
    setSessionType({ commit }, payload) {
      commit("saveSessionType", payload);
    },
    updateSessionAddress({ commit }, payload) {
      commit("saveSessionAddress", {
        ...payload
      });
    },
    async updateSessionAddressReverseCoding({ commit }, payload) {
      const response = await this._vm.$mapbox.geocoding
        .reverseGeocode({
          query: payload.coordinate,
          limit: 1
        })
        .send();
      const match = response.body;
      const feature = match.features[0];

      feature.geometry.suggested_coordinates = feature.geometry.coordinates;
      feature.geometry.coordinates = [
        payload.coordinate[0],
        payload.coordinate[1]
      ];

      commit("saveSessionAddress", {
        location_name: feature.text,
        address: feature.place_name,
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0]
      });
    },
    updateSearchResults({ commit }, payload) {
      commit("saveSearchResults", payload);
    },
    updateSearchResultLimit({ commit }, payload) {
      commit("saveSearchResultLimit", payload);
    },
    updateSearchLocation({ commit }, payload) {
      commit("saveSearchLocation", payload);
    },
    updateDragCustom({ commit }, payload) {
      commit("toggleIsDragCustomMode", payload);
    },
    finishDragCustom({ commit }, payload) {
      commit("toggleFinishCustomMode", payload);
    },
    updateLoadingPresetStatus({ commit }, payload) {
      commit("loadingStatus", payload);
    },
    async doSearch({ state, commit }) {
      commit("loadingStatus", true);

      this._vm.$map.controller.clearSearchResults();

      const results = await instantSearchService.search({
        id: state.search.selected_image_id,
        limit: state.search.resultLimit
      });

      if (results && results.data && results.data.result_tile_ids) {
        const resultsGeoJSON = this._vm.$map.controller.loadSearchResults(
          results.data
        );
        commit("saveSearchResults", resultsGeoJSON);
      }

      commit("loadingStatus", false);
    },
    /**
     * save current preset. Payload should be map-internal state values
     * example payload:
     * {
     *  coordinates,
     *  roi
     * }
     * @param {*} payload
     */
    async savePreset({ dispatch, rootGetters, state, commit }) {
      // trang.tn edit to remove disc login
      // if (!rootGetters["user/isLoggedIn"]) { //chua login thi show login
      //   return dispatch("toggleLoginDialog", {}, { root: true });
      // }

      commit("savingStatus", true);
      const center = toLonLat(this._vm.$map.getView().getCenter());
      const presetData = {
        location_name: state.session.location_name,
        address: state.session.address,
        longitude: center[0],
        latitude: center[1],
        session_type: state.session.session_type,
        roi: {},
        extras: {
          zoom_level: this._vm.$map.getView().getZoom(),
          features: {}
        }
      };

      //check for session type
      if (state.session.session_type === SessionTypes.detect) {
        onSavingDetectSession(this._vm.$map, presetData, state);
      } else {
        //save instant search
        onSavingInstantSearchSession(this._vm.$map, presetData, state);
      }

      const presetDataJson = JSON.parse(JSON.stringify(presetData));
      const lastSavedSessionDataJson = JSON.parse(
        JSON.stringify(
          omit(state.lastSavedSessionData, [
            "id",
            "user",
            "modified",
            "created",
            "request_ip"
          ])
        )
      );

      if (isEqual(presetDataJson, lastSavedSessionDataJson)) {
        return this._vm.$dialog.confirm({
          type: "warning",
          text: i18n.t("save_preset_messages.preset_no_change"),
          actions: {
            true: {
              color: "primary",
              text: "OK"
            }
          }
        });
      }

      try {
        const response = await presetService.save(presetData);

        commit("saveLastSavedSessionData", response.data);
        commit("savingStatus", false);

        this._vm.$dialog.confirm({
          type: "info",
          text: i18n.t("save_preset_messages.preset_save_success"),
          actions: {
            true: {
              color: "primary",
              text: "OK"
            }
          }
        });
      } catch (e) {
        this._vm.$dialog.error({
          text: i18n.t("save_preset_messages.preset_save_error")
        });
      }
    }
  }
};

function onSavingInstantSearchSession(map, presetData, state) {
  presetData.roi = map.controller.writeFeatures(
    Constants.SEARCH_LAYER_NAMES.LAYER_SELECTED
  );
  presetData.extras.selectedAoiCenter = [...state.search.location];
  presetData.extras.features = {
    SelectedLayer: map.controller.writeFeatures(
      Constants.SEARCH_LAYER_NAMES.LAYER_SELECTED
    ),
    ResultLayer: map.controller.writeFeatures(
      Constants.SEARCH_LAYER_NAMES.LAYER_RESULT
    )
  };
}

function onSavingDetectSession(map, presetData, state) {
  presetData.extras = {
    ...presetData.extras,
    activeMapId: state.selectedYear,
    filter: {
      available: state.availableObjectTypes,
      selected: state.selectedObjectTypes
    }
  };
  //current features layer
  const writer = new GeoJSON({
    dataProjection: Constants.DEFAULT_DATA_PROJECTION,
    featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
  });
  //layer mode
  if (state.isDragCustomMode) {
    const customAreaLayer = map
      .getLayers()
      .getArray()
      .find(item => item.get("name") === Constants.CUSTOM_AREA_LAYER_NAME);
    if (!customAreaLayer) {
      throw new Error("Custom drag layer does not exist");
    }
    const customAreaLayerFeatures = writer.writeFeaturesObject(
      customAreaLayer.getSource().getFeatures()
    );
    presetData.roi = map.get(Constants.MAP_CENTER_ROI_CUSTOM_PROP);
    presetData.extras = {
      ...presetData.extras,
      isDragCustomMode: state.isDragCustomMode,
      customAreaAoi: customAreaLayerFeatures
    };
  } else {
    presetData.roi = map.get(Constants.MAP_CENTER_ROI_PROP);
  }
  const layer = map.controller.getFeatureLayer();
  const geoData = writer.writeFeaturesObject(layer.getSource().getFeatures());
  presetData.extras.features = geoData;
}
