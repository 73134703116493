import Constants from "../config/constants";
import axios from "../config/axios";

export default {
  search({ id: image_id, limit }) {
    const requestUrl = `${Constants.API.INSTANT_SEARCH}?tile=${image_id}.png&limit=${limit}`;
    // return {
    //   data: {
    //     result_tile_ids: [`${image_id}.png`]
    //   }
    // };
    return axios.get(requestUrl);
  }
};
