import axios from "../config/axios";

export default {
  getObjectTypes() {
    return axios.get("/api/v1/discovery/object-type/nested/");
  },
  getObjectType({ object_type_id }) {
    return axios.get(`/api/v1/discovery/object-type/${object_type_id}/`);
  }
};
