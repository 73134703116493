import axios from "../config/axios";

const userService = {
  login(credentials) {
    return axios.post("/api/v1/token/create/", credentials);
  },
  register(credentials) {
    return axios.post("/api/v1/user/", credentials);
  },
  getProfile() {
    return axios.get("/api/v1/user/me/");
  },
  async refreshAccessToken(refresh) {
    try {
      const accessResp = await axios.post("/api/v1/token/refresh/", {
        refresh
      });
      return accessResp.data.access;
    } catch (error) {
      return null;
    }
  },
  verifyAccessToken(access) {
    return axios.post("/api/v1/token/verify/", { token: access });
  },
  async ensureCredentialTokens(refresh, access) {
    let refresh_ = refresh,
      access_;
    if (!refresh) return { refresh: null, access: null };

    if (!access) {
      access_ = await this.refreshAccessToken(refresh);
    } else {
      try {
        await this.verifyAccessToken(access);
        access_ = access;
      } catch (error) {
        access_ = await this.refreshAccessToken(refresh);
      }
    }

    if (!access_) refresh_ = null;

    return { refresh: refresh_, access: access_ };
  }
};

export default userService;
