import * as Url from "url-parse";

import axios from "../config/axios";

export default {
  save(session_data) {
    const { session_type } = session_data;

    if (session_type !== "detect" && session_type !== "search") {
      return false;
    }
    return axios.post("/api/v1/discovery/session/", {
      ...session_data
    });
  },
  list(url, session_type) {
    if (!url) {
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/discovery/session/`;
    }
    const parsedUrl = Url(url, true);
    parsedUrl.query = {
      ...parsedUrl.query,
      session_type
    };
    return axios.get(parsedUrl.toString());
  },
  update({ session_id, session_data }) {
    return axios.patch(`/api/v1/discovery/session/${session_id}/`, {
      ...session_data
    });
  }
};
