import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "@/messages/en.json";
import ja from "@/messages/ja.json";

Vue.use(VueI18n);

const messages = {
  en,
  ja
};

const i18n = new VueI18n({
  locale:
    localStorage.getItem("locale") || navigator.language.substr(0, 2) || "en",
  fallbackLocale: "en",
  messages
});

export default i18n;
