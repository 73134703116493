<template>
  <v-card class="login-form pa-10">
    <form>
      <v-card-title class="justify-center">
        <img :src="require('@/assets/logo.png')" height="54" />
      </v-card-title>

      <v-card-text
        class="text-center"
        v-if="formError != '' && !isDirty"
        @keyup:enter:native="onSubmit"
      >
        <p class="red--text">{{ this.formError }}</p>
      </v-card-text>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                solo
                flat
                :placeholder="$t('login_form.email')"
                background-color="white"
                v-model.trim="form.email"
                @change="touch('email')"
                @blur="touch('email')"
                :error-messages="emailErrors"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                solo
                flat
                :placeholder="$t('login_form.password')"
                background-color="white"
                v-model="form.password"
                @change="touch('password')"
                @blur="touch('password')"
                :error-messages="passwordErrors"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row class="fill-height">
            <v-col class="pt-0 pb-0 text-left d-flex align-center" cols="6">
              <v-btn
                color="secondary"
                text
                class="text-small"
                depressed
                dense
                @click="
                  $store.dispatch('toggleLoginFormDialog', 'register', {
                    root: true
                  })
                "
                >{{ $t("login_form.go_to_register") }}</v-btn
              >
            </v-col>
            <v-col class="pt-0 pb-0 text-right" cols="6">
              <v-btn
                color="secondary"
                depressed
                :loading="loading"
                @click="onSubmit"
                width="100%"
                >{{ $t("buttons.login") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import { VuetifyFormMixin, getYupErrors, getServerErrors } from "./utils";
import { loginSchema } from "./schemas";

export default {
  mixins: [VuetifyFormMixin],
  schema: loginSchema,
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      formError: "",
      showPassword: false,
      success: false
    };
  },
  methods: {
    onSubmitCallback() {
      this.formError = "";
      this.$options.schema
        .validate(this.form, { abortEarly: false })
        .then(credentials => {
          this.loading = true;
          this.errors = {};
          this.$store.dispatch("user/doLogin", credentials).catch(error => {
            this.loading = false;
            if (error.response && error.response.status === 400) {
              this.errors = getServerErrors(error.response.data);
            }
            if (error.response && error.response.status === 401) {
              this.formError = this.$t("login_form.errors.auth_failed");
            }
          });
        })
        .catch(errors => {
          this.errors = getYupErrors(errors);
        });
    },
    onResetCallback() {
      this.formError = "";
      this.success = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.login-form {
  background: url("../../assets/df_bg.jpg") no-repeat center center;
  background-size: cover;
}
.text-small {
  font-size: 12px;
}
</style>
