<template>
  <v-card>
    <form ref="form">
      <v-card-title class="justify-center">
        <span>{{ $t("login_form.register_form_title") }}</span>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('login_form.email')"
                v-model.trim="form.email"
                @change="touch('email')"
                @blur="touch('email')"
                :error-messages="emailErrors"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.password"
                @change="touch('password')"
                @blur="touch('password')"
                :error-messages="passwordErrors"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('login_form.password')"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.re_password"
                @change="touch('re_password')"
                @blur="touch('re_password')"
                :error-messages="re_passwordErrors"
                :append-icon="
                  showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
                "
                :type="showPasswordConfirmation ? 'text' : 'password'"
                :label="$t('login_form.password_confirmation')"
                @click:append="
                  showPasswordConfirmation = !showPasswordConfirmation
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0" cols="12">
              <v-checkbox
                class="checkbox no-validate"
                color="secondary"
                v-model="form.subscribed"
              >
                <template v-slot:label>
                  <small
                    class="ma-0 pa-0"
                    v-on:click="onTermClick"
                    v-html="$t('login_form.subscribed_message')"
                  ></small>
                </template>
              </v-checkbox>
              <p class="red--text pl-8">
                <small>{{ $t("login_form.subscribed_warning") }}</small>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0" cols="12">
              <v-checkbox
                v-model="form.acceptTermsOfService"
                @change="touch('acceptTermsOfService')"
                :error-messages="acceptTermsOfServiceErrors"
                color="secondary"
              >
                <template v-slot:label>
                  <p class="ma-0 pa-0" v-on:click="onTermClick">
                    <small v-html="$t('login_form.term_of_service')"></small>
                  </p>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <p class="red--text text-center">
                <small v-html="$t('login_form.activation_guide')"></small>
              </p>
            </v-col>
          </v-row>
          <v-row v-if="!loading && success">
            <v-col class="pt-0 pb-0 text-center" cols="12">
              <p class="green--text ma-0">
                {{ $t("login_form.register_successfully") }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row class="fill-height">
            <v-col class="pt-0 pb-0 text-left d-flex align-center" cols="6">
              <v-btn
                color="secondary"
                text
                depressed
                dense
                @click="
                  $store.dispatch('toggleLoginFormDialog', 'login', {
                    root: true
                  })
                "
              >
                {{ $t("login_form.go_to_login") }}
              </v-btn>
            </v-col>
            <v-col class="pt-0 pb-0 text-right" cols="6">
              <v-btn
                color="secondary"
                :disabled="loading || !form.acceptTermsOfService"
                depressed
                :loading="loading"
                type="submit"
                @click="onSubmit"
                width="100%"
                >{{ $t("buttons.register") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import { registerSchema } from "./schemas";
import { VuetifyFormMixin, getYupErrors, getServerErrors } from "./utils";
import { delay } from "../../lib/helper";

export default {
  mixins: [VuetifyFormMixin],
  schema: registerSchema,
  data() {
    return {
      form: {
        email: "",
        password: "",
        re_password: "",
        subscribed: false,
        acceptTermsOfService: false
      },
      showPassword: false,
      showPasswordConfirmation: false,
      success: false
    };
  },
  methods: {
    onTermClick(event) {
      if (event.target !== event.currentTarget) {
        event.stopPropagation();
      }
    },
    onResetCallback() {
      this.success = false;
    },
    showLoginForm() {
      this.$store.dispatch("toggleLoginFormDialog", "login", { root: true });
      this.reset();
    },
    async onSubmitCallback() {
      this.$options.schema
        .validate(this.form, { abortEarly: false })
        .then(async credentials => {
          this.loading = true;
          this.errors = {};
          this.$store
            .dispatch("user/doRegister", credentials)
            .then(async () => {
              this.loading = false;
              this.success = true;
              await delay(1000);
              this.showLoginForm();
            })
            .catch(error => {
              this.loading = false;
              if (error.response && error.response.status === 400) {
                this.errors = getServerErrors(error.response.data);
              }
            });
        })
        .catch(error => {
          this.errors = getYupErrors(error);
        });
    }
  }
};
</script>

<style>
.checkbox .v-input__slot {
  margin: 0 !important;
}
.checkbox.no-validate .v-messages {
  display: none;
}
</style>
