<template>
  <v-app-bar app color="#012A5D" flat dark>
    <v-toolbar-title class="px-5">
      <router-link to="/">
        <v-img
          :src="require('@/assets/logo.png')"
          width="300"
          height="60"
          position="left center"
        ></v-img>
      </router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <df-contact-button
      url="https://datafluct.com/service/discovery"
    ></df-contact-button>
    <v-toolbar-title>
      <!-- //trang.tn commented to remove login -->
      <!-- <v-container fluid v-if="$store.getters['user/isLoggedIn']">
        <v-row>
          <v-menu offset-y offset-x nudge-left="12" nudge-top="-5">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dark width="160" dense class="pa-0">
              <v-list-item width="140">
                <v-list-item-title>
                  <v-icon small>mdi-account-outline</v-icon>
                  <span class="pl-2">
                    {{ $store.getters["user/profileName"] }}
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="$store.dispatch('user/logout')" width="140">
                <v-list-item-title>
                  <v-icon small>mdi-logout</v-icon>
                  <span class="pl-3">{{ $t("user.logout") }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-container> -->
      <!-- <v-menu offset-y offset-x nudge-top="-5" v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-btn>
        </template>
        <v-list dark width="160" dense class="pa-0">
          <v-list-item key="login" @click="onLogin">
            <v-list-item-title>
              <v-icon small>mdi-login</v-icon>
              <span class="pl-3">{{ $t("user.login") }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item dense key="register" @click="onRegister">
            <v-list-item-title>
              <v-icon small>mdi-account-plus-outline</v-icon>
              <span class="pl-3">{{ $t("user.register") }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-app-bar-nav-icon @click="showSidebar"></v-app-bar-nav-icon>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import bus from "../event-bus";
import DfContactButton from "./general/DfContactButton.vue";
export default {
  components: { DfContactButton },
  methods: {
    onRegister() {
      this.$store.dispatch(
        "toggleLoginDialog",
        { form: "register" },
        { root: true }
      );
    },
    onLogin() {
      this.$store.dispatch(
        "toggleLoginDialog",
        { form: "login" },
        { root: true }
      );
    },
    showSidebar() {
      bus.$emit("show-sidebar");
    },
  },
};
</script>

<style lang="scss">
.v-image__image {
  background-size: 240px !important;
}
</style>
