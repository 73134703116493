import { cloneDeep } from "lodash";

function getErrorsForField($v, field) {
  if ($v.dirty[field]) return [];
  return $v.errors[field] ? cloneDeep($v.errors[field]) : [];
}

const VuetifyFormMixin = {
  data: function() {
    return {
      dirty: {},
      errors: {},
      cachedForm: {},
      formDefaults: null,
      loading: false
    };
  },
  created: function() {
    this.formDefaults = cloneDeep(this.form);
  },
  beforeCreate: function() {
    const options = this.$options;
    const schema = options.schema;
    if (!schema) return;

    if (!options.computed) options.computed = {};

    Object.keys(schema.describe().fields).forEach(field => {
      if (!options.computed[field + "Errors"])
        options.computed[field + "Errors"] = function() {
          return getErrorsForField(this, field);
        };
    });
  },
  computed: {
    isDirty: function() {
      let isDirty = false;
      Object.keys(this.dirty).forEach(key => {
        isDirty = isDirty || this.dirty[key];
      });
      return isDirty;
    }
  },
  watch: {
    loading() {
      this.$store.dispatch("toggleLoginDialogPersistent");
    }
  },
  methods: {
    touch(field) {
      let dirty = {};
      for (let key in this.form) {
        if (key === field && this.form[field] !== this.cachedForm[field]) {
          if (typeof this.form[field] !== "boolean") dirty[key] = true;
          this.errors[key] = [];
        } else if (
          key === field &&
          this.form[field] === this.cachedForm[field]
        ) {
          dirty[key] = false;
        } else {
          dirty[key] = this.dirty[key];
        }
      }
      this.dirty = dirty;
    },
    touchform() {
      let dirty = {};
      for (let key in this.form) {
        dirty[key] = false;
      }
      this.dirty = dirty;
    },
    reset() {
      this.dirty = {};
      this.errors = {};
      this.form = cloneDeep(this.formDefaults);
      this.cachedForm = {};
      typeof this.onResetCallback === "function" && this.onResetCallback();
    },
    onSubmit(e) {
      e.preventDefault();
      this.onSubmitCallback();
      this.cachedForm = cloneDeep(this.form);
      this.touchform();
    }
  }
};

function getYupErrors(errors) {
  const errors_ = {};
  errors.inner.forEach(err => {
    errors_[err.path] = err.errors;
  });
  return errors_;
}

function getServerErrors(errors) {
  const errors_ = {};
  Object.keys(errors).forEach(key => {
    errors_[key] = errors[key];
  });
  return errors_;
}

export { VuetifyFormMixin, getErrorsForField, getYupErrors, getServerErrors };
