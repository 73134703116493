/* eslint-disable no-console */
import axios from "axios";
import i18n from "./i18n";
import promiseFinally from "promise.prototype.finally";

promiseFinally.shim();

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.request.use(
  request => {
    const access_token = localStorage.getItem("access_token");

    if (access_token) {
      request.headers["Authorization"] = "JWT " + access_token;
    }

    return request;
  },
  error => {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

const retryRequest = (config, new_access_token) => {
  config.headers["Authorization"] = "JWT " + new_access_token;
  return new Promise((resolve, reject) => {
    axios
      .request(config)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// handle Unauthorized
const finalExit = error => {
  return new Promise((resolve, reject) => {
    reject(error);
  });
};

const refreshTokenFn = refreshToken =>
  new Promise((resolve, reject) => {
    axios
      .post("/api/v1/token/refresh/", {
        refresh: refreshToken
      })
      .then(response => {
        const newToken = response.data.access;
        localStorage.setItem("access_token", newToken);
        resolve(newToken);
      })
      .catch(error => {
        reject(error);
      });
  });

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status >= 500) {
      axios.$v.$dialog.error({
        icon: "fa-exclamation-triangle",
        text: i18n.t("errors.http_over_or_equal_to_500"),
        title: i18n.t("errors.title")
      });
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      const { config } = error;
      const url = config.url.replace(config.baseURL, "");
      const refreshToken = localStorage.getItem("refresh_token");

      if (!refreshToken) {
        return finalExit(error);
      }

      if (url === "/api/v1/token/refresh/") {
        axios.$v.$dialog.error({
          icon: "fa-exclamation-triangle",
          text: i18n.t("errors.required_relogin"),
          title: i18n.t("errors.title")
        });

        //refresh token failed, force logout procedure
        axios.$v.$store.dispatch("user/logout");
        return finalExit(error);
      }

      return refreshTokenFn(refreshToken)
        .then(token => retryRequest(config, token))
        .catch(error => {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        });
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

export default axios;
